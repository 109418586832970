import React, { Component } from 'react';
import Widget from 'components/Widget/Widget';
import { Row, Col } from 'reactstrap';
import { connect } from 'react-redux';
import { withRouter } from 'utils/withRouter';
import backIcon from 'images/icons/arrow-left.svg';
import DirectoriesView from './DirectoriesView';
import actions from 'actions/OneDrive/OneDrivePreviewActions';
import { fileIcons } from 'actions/common';
import formActions from 'actions/OneDrive/OneDriveFormActions';
class OneDrivePreviewPage extends Component {
  state = {
    legalHoldObj: {},
    isConfirmed: false
  };
  componentDidMount() {
    const { dispatch, params } = this.props;
    const { name: firstName, surName: lastName, email, userName } = this.props.currentUser || {};
    const expireonUser = {
      firstName,
      lastName,
      email,
      userName
    };
    const legalHoldId = params?.id;
    dispatch(actions.doGetDirectoriesHierarchy(legalHoldId));
    dispatch(formActions.doFind(legalHoldId, expireonUser));
  }
  componentDidUpdate(prevProps) {
    const { dispatch } = this.props;
    if (prevProps.legalHoldRecord !== this.props.legalHoldRecord) {
      this.setState({
        legalHoldObj: this.props?.legalHoldRecord,
        isConfirmed: this.props?.legalHoldRecord?.legalHoldConfirmed
      });
    }
  }

  formatData = (data) => {
    const custodianIds = [];
    data?.custodians?.map((item) => {
      custodianIds.push(item?.custodianId);
    });
    const dataToSubmit = {
      legalHoldName: data.legalHoldName,
      legalHoldDescription: data.legalHoldDescription,
      legalCaseId: data?.legalCaseId,
      custodians: custodianIds,
      externalCustodians: data.externalCustodians,
      dateFrom: data.dateFrom,
      dateTo: data.dateTo,
      containerIds: data.containerIds,
      legalHoldId: data.legalHoldId
    };
    return dataToSubmit;
  };

  handleConfirmLegalHold = async () => {
    const { dispatch, legalHoldRecord } = this.props;
    const legalHoldId = legalHoldRecord.legalHoldId;
    const legalCaseId = legalHoldRecord.legalCaseId;
    const expireonUser = legalHoldRecord.expireonUser;
    const data = this.formatData(legalHoldRecord);
    await dispatch(
      formActions.doConfirmFileLH(
        { ...data },
        {
          expireonUser
        }
      )
    ).then(() => {
      this.setState({
        isConfirmed: true
      });
    });
  };

  handleDiscardLegalHold = async () => {
    const { dispatch, legalHoldRecord } = this.props;
    const legalHoldId = legalHoldRecord.legalHoldId;
    const legalCaseId = legalHoldRecord.legalCaseId;
    const expireonUser = legalHoldRecord.expireonUser;
    await dispatch(formActions.doDiscardLegalHold(legalCaseId, legalHoldId, expireonUser)).then(
      () => {
        this.setState({
          isConfirmed: true
        });
        this.props.navigate(`/app/LegalCase/${legalCaseId}`);
      }
    );
  };

  render() {
    const { navigate } = this.props;
    return (
      <div>
        <Widget>
          <div className='d-flex justify-content-between align-items-center'>
            <div className={'d-flex mb-4 align-items-center'}>
              <button className={'btn bg-transparent me-2 ps-0'} onClick={() => navigate(-1)}>
                <img src={backIcon} alt={'arrow-back'} />
              </button>
              <h4 className={`mb-1 first-headline-text d-flex align-items-center mt-1`}>Back</h4>
            </div>

            {!this.state.legalHoldObj?.legalHoldConfirmed && !this.state.isConfirmed && (
              <div className='d-flex'>
                <button
                  type='button'
                  className='btn danger-btn d-flex align-items-center first-body-text me-2'
                  onClick={() => {
                    this.handleDiscardLegalHold();
                  }}
                >
                  Discard
                </button>
                <button
                  type='button'
                  className='btn d-flex align-items-center first-body-text'
                  onClick={() => {
                    this.handleConfirmLegalHold();
                  }}
                >
                  Confirm
                </button>
              </div>
            )}
          </div>
          <Row sm={12} style={{ height: '100vh' }}>
            <Col sm={3}>
              <Widget style={{ height: '100vh' }}>
                <h3 className='text-center mb-3'>Directories</h3>
                <DirectoriesView
                  nodes={this.props?.directoriesList}
                  dispatch={this.props.dispatch}
                />
              </Widget>
            </Col>
            <Col sm={4}>
              <Widget style={{ height: '100vh' }}>
                <h3 className='text-center mb-3'>File List</h3>
                <div className='ms-3'>
                  {this.props?.filesList?.map((item, index) => {
                    const filetype = item?.split('.').pop();
                    return (
                      <p key={index}>
                        <span>{fileIcons(filetype)}</span>
                        <span>{item}</span>
                      </p>
                    );
                  })}
                </div>
              </Widget>
            </Col>
            <Col sm={5}>
              <Widget style={{ height: '100vh' }}>
                <h3 className='text-center'>Preview</h3>
                <div>
                  <p>File preview will appear here</p>
                </div>
              </Widget>
            </Col>
          </Row>
        </Widget>
      </div>
    );
  }
}

function mapStateToProps(store) {
  return {
    directoriesList: store.OneDrive.list.directoriesList,
    filesList: store.OneDrive.list.filesList,
    currentUser: store.auth.currentUser,
    legalHoldRecord: store.OneDrive.form.legalHoldRecord
  };
}

export default withRouter(connect(mapStateToProps)(OneDrivePreviewPage));
