import Errors from 'components/FormItems/error/errors';
import { toast } from 'react-toastify';
import config from '../../config';
import { Client } from '../../legal-api';
import listActions from 'actions/LegalHold/LegalHoldListActions';
import { downloadService } from 'services/downloadService';
import { store } from 'index';
import { defaultOptions } from '../../services/userService';
import { isLegalAdmin } from 'utils/isLegalAdmin';

async function doCreateLegalHold(data, expireonUser) {
  const legalHold = await new Client(config.externalApi).legalHoldPOST({
    ...data,
    ...expireonUser
  });
  return legalHold;
}

async function doFindLegalHold(caseId, expireonUser) {
  const legalHold = await new Client(config.externalApi).legalHoldGET(
    caseId,
    JSON.stringify(expireonUser)
  );
  const legalHoldStats = await new Client(config.externalApi).stats(legalHold.legalHoldId);
  return Object.assign(legalHold, legalHoldStats);
}

async function doUpdateLegalHold(holdId, data) {
  return await new Client(config.externalApi).legalHoldEditLegalHold(holdId, data);
}

async function doFetchContainers(isChat, legalHoldType) {
  return await new Client(config.externalApi).legalHoldContainersGET(isChat, legalHoldType);
}

async function doFetchMessages() {
  return await new Client(config.externalApi).legalHoldMessageClassGET();
}
async function doHandleDownloadExample(downloadUrl) {
  await downloadService(downloadUrl, 'GET', undefined);
}

async function doFetchLegalHoldNames(legalCaseId, isChat) {
  const currentUser = store.getState().auth.currentUser;
  const baseUrl = isChat
    ? `${config.externalApi}/legal/api/LegalHold/Chat/GetLegalHoldNames?legalCaseId=${legalCaseId}&loggedUser=${currentUser.userName}`
    : `${config.externalApi}/legal/api/LegalHold/Mail/GetLegalHoldNames?legalCaseId=${legalCaseId}&loggedUser=${currentUser.userName}`;

  const response = await fetch(baseUrl, {
    ...defaultOptions,
    method: 'GET'
  });
  const data = await response.json();
  return data;
}

async function doGetLegalHoldStats(legalHoldId) {
  const legalHoldStats = await new Client(config.externalApi).stats(legalHoldId);
  return legalHoldStats;
}

async function doCalculateStatisticsForLH(data) {
  const url = `${config.externalApi}/legal/api/LegalHold/Mail/CalculateStatisitcsForLh`;
  const response = await fetch(url, {
    ...defaultOptions,
    method: 'POST',
    body: JSON.stringify(data)
  });
  return response.json();
}

async function discardLegalHold(legalCaseId, expireonUser, legalHoldId, isChat) {
  const { name: firstName, surName: lastName, email, userName } = expireonUser || {};
  let loggedUserString = { firstName, lastName, email, userName };
  const legalAdmin = isLegalAdmin(expireonUser);
  const baseUrl = isChat
    ? `${config.externalApi}/legal/api/LegalHold/Chat/${legalHoldId}/${legalCaseId}?isLegalAdmin=${legalAdmin}`
    : `${config.externalApi}/legal/api/LegalHold/Mail/${legalHoldId}/${legalCaseId}?isLegalAdmin=${legalAdmin}`;

  await fetch(baseUrl, {
    ...defaultOptions,
    method: 'DELETE',
    body: JSON.stringify(loggedUserString)
  });
}

async function getCustodians(pageNumber, pageSize, searchString) {
  let baseUrl = `${config.externalApi}/legal/api/Custodian/GetCustodians?`;
  if (pageNumber !== undefined && pageNumber !== null) {
    baseUrl += 'pageNumber=' + encodeURIComponent('' + pageNumber) + '&';
  }
  if (pageSize !== undefined && pageSize !== null) {
    baseUrl += 'pageSize=' + encodeURIComponent('' + pageSize) + '&';
  }
  if (searchString !== undefined && searchString !== null && searchString !== '') {
    baseUrl += 'searchString=' + encodeURIComponent('' + searchString) + '&';
  }
  baseUrl = baseUrl.replace(/[?&]$/, '');

  const response = await fetch(baseUrl, {
    ...defaultOptions,
    method: 'GET'
  });
  const data = await response.json();
  return data;
}

async function getCustodianIdentities(custodianId) {
  const response = await fetch(
    `${config.externalApi}/legal/api/Custodian/GetCustodianIdentities?custodianId=${custodianId}`,
    {
      ...defaultOptions,
      method: 'GET'
    }
  );
  const data = await response.json();
  return data;
}

async function getAllCustodiansData(custodiansList) {
  const response = await fetch(`${config.externalApi}/legal/api/LegalHold/GetAllCustodiansData`, {
    ...defaultOptions,
    method: 'POST',
    body: JSON.stringify(custodiansList)
  });
  const data = await response.json();
  return data;
}

async function getChannels(containersList) {
  const response = await fetch(`${config.externalApi}/legal/api/LegalHold/GetChannels`, {
    ...defaultOptions,
    method: 'POST',
    body: JSON.stringify(containersList)
  });
  const data = await response.json();
  return data;
}

async function createChatLH(data) {
  const url = `${config.externalApi}/legal/api/LegalHold/Chat/SaveChatLegalHold`;
  const response = await fetch(url, {
    ...defaultOptions,
    method: 'POST',
    body: JSON.stringify(data)
  });
  return response.json();
}

async function confirmDiscardChatLH(legalHoldId, isConfirmed) {
  const url = `${config.externalApi}/legal/api/LegalHold/Chat/ConfirmDiscardChatLegalHold?LegalHoldId=${legalHoldId}&IsLegalHoldConfirmed=${isConfirmed}`;
  const response = await fetch(url, {
    ...defaultOptions,
    method: 'POST'
  });
  return response.json();
}

const actions = {
  doFind: (id, expireonUser, navigate) => async (dispatch) => {
    try {
      dispatch({
        type: 'LEGALHOLD_FORM_FIND_STARTED'
      });

      doFindLegalHold(id, expireonUser).then((res) => {
        const record = res;

        dispatch({
          type: 'LEGALHOLD_FORM_FIND_SUCCESS',
          payload: record
        });
      });
    } catch (error) {
      Errors.handle(error);
      dispatch({
        type: 'LEGALHOLD_FORM_FIND_ERROR'
      });

      navigate('/app/LegalHold');
    }
  },
  doFindContainers: (navigate, isChat, legalHoldType) => async (dispatch) => {
    try {
      dispatch({
        type: 'LEGALHOLD_FORM_FIND_CONTAINERS_STARTED'
      });

      doFetchContainers(isChat, legalHoldType).then((res) => {
        const record = res;

        dispatch({
          type: 'LEGALHOLD_FORM_FIND_CONTAINERS_SUCCESS',
          payload: record
        });
      });
    } catch (error) {
      Errors.handle(error);
      dispatch({
        type: 'LEGALHOLD_FORM_FIND_ERROR'
      });

      navigate('/app/LegalHold');
    }
  },
  doFindMessages: (navigate) => async (dispatch) => {
    try {
      dispatch({
        type: 'LEGALHOLD_FORM_FIND_MESSAGE_STARTED'
      });

      doFetchMessages().then((res) => {
        const record = res;

        dispatch({
          type: 'LEGALHOLD_FORM_FIND_MESSAGE_SUCCESS',
          payload: record
        });
      });
    } catch (error) {
      Errors.handle(error);
      dispatch({
        type: 'LEGALHOLD_FORM_FIND_ERROR'
      });
      navigate('/app/LegalHold');
    }
  },
  doCreate: (values, expireonUser) => async (dispatch) => {
    try {
      dispatch({
        type: 'LEGALHOLD_FORM_CREATE_STARTED'
      });

      doCreateLegalHold(values, expireonUser).then((res) => {
        dispatch({
          type: 'LEGALHOLD_FORM_CREATE_SUCCESS'
        });
        toast.success('Legal Hold created', { icon: false });
      });
    } catch (error) {
      Errors.handle(error);
      dispatch({
        type: 'LEGALHOLD_FORM_CREATE_ERROR'
      });
    }
  },
  doUpdate: (id, values) => async (dispatch, getState) => {
    try {
      dispatch({
        type: 'LEGALHOLD_FORM_UPDATE_STARTED'
      });

      await doUpdateLegalHold(id, values);

      dispatch({
        type: 'LEGALHOLD_FORM_UPDATE_SUCCESS'
      });
      dispatch({
        type: 'LEGALHOLD_FORM_CLOSE_CONFIRM'
      });
      dispatch(
        listActions.doFetch({
          legalCaseId: values.legalCaseId,
          pageNumber: values.pageNumber,
          pageSize: values.pageSize
        })
      );
      toast.success('Legal Hold updated', { icon: false });
    } catch (error) {
      Errors.handle(error);
      dispatch({
        type: 'LEGALHOLD_FORM_UPDATE_ERROR'
      });
    }
  },
  handleDownloadExample: (downloadUrl) => async (dispatch) => {
    try {
      dispatch({
        type: 'LEGALHOLD_DOWNLOAD_EXAMPLE_STARTED'
      });

      doHandleDownloadExample(downloadUrl).then((response) => {
        dispatch({
          type: 'LEGALHOLD_DOWNLOAD_EXAMPLE_SUCCESS'
        });
      });
    } catch (error) {
      Errors.handle(error);
      dispatch({
        type: 'LEGALHOLD_DOWNLOAD_EXAMPLE_ERROR'
      });
    }
  },
  doFindLegalHoldNames: (legalCaseId, isChat) => async (dispatch) => {
    try {
      dispatch({
        type: 'LEGAL_HOLD_FORM_FIND_NAMES_STARTED'
      });

      doFetchLegalHoldNames(legalCaseId, isChat).then((res) => {
        dispatch({
          type: 'LEGAL_HOLD_FORM_FIND_NAMES_SUCCESS',
          payload: res
        });
      });
    } catch (error) {
      Errors.handle(error);
      dispatch({
        type: 'LEGAL_HOLD_FORM_FIND_NAMES_ERROR'
      });
    }
  },
  doOpenConfirm: () => async (dispatch) => {
    dispatch({
      type: 'LEGALHOLD_FORM_OPEN_CONFIRM'
    });
  },
  doCloseConfirm: () => async (dispatch) => {
    dispatch({
      type: 'LEGALHOLD_FORM_CLOSE_CONFIRM'
    });
  },
  doGetLegalHoldStatistics: (legalHoldId) => async (dispatch) => {
    try {
      dispatch({
        type: 'LEGAL_HOLD_FORM_GET_STATISTICS_STARTED'
      });

      doGetLegalHoldStats(legalHoldId).then((res) => {
        dispatch({
          type: 'LEGAL_HOLD_FORM_GET_STATISTICS_SUCCESS',
          payload: res
        });
      });
    } catch (error) {
      Errors.handle(error);
      dispatch({
        type: 'LEGAL_HOLD_FORM_GET_STATISTICS_ERROR'
      });
    }
  },
  doSetCurrentStep: (currentStep) => async (dispatch) => {
    dispatch({
      type: 'LEGAL_HOLD_FORM_CURRENT_STEP',
      payload: currentStep
    });
  },
  doCalculateStatsForLH: (data) => async (dispatch) => {
    try {
      dispatch({
        type: 'CALCULATE_STATS_STARTED'
      });
      const res = await doCalculateStatisticsForLH(data);
      dispatch({
        type: 'CALCULATE_STATS_SUCCESS',
        payload: res
      });
    } catch (error) {
      Errors.handle(error);
      dispatch({
        type: 'CALCULATE_STATS_ERROR'
      });
    }
  },
  doDiscardLegalHold: (legalCaseId, expireonUser, legalHoldId, isChat) => async (dispatch) => {
    try {
      dispatch({
        type: 'LEGALHOLD_DISCARD_STARTED'
      });

      await discardLegalHold(legalCaseId, expireonUser, legalHoldId, isChat);
      dispatch({
        type: 'LEGALHOLD__DISCARD_SUCCESS'
      });
    } catch (error) {
      Errors.handle(error);
      dispatch({
        type: 'LEGALHOLD_DISCARD_ERROR'
      });
    }
  },
  doGetDataToSubmit: (data) => async (dispatch) => {
    dispatch({
      type: 'LEGAL_HOLD_DATA_TO_SUBMIT',
      payload: data
    });
  },
  doGetCustodians: (pageNumber, pageSize, searchString) => async (dispatch) => {
    try {
      dispatch({
        type: 'CUSTODIANS_LIST_FETCH_STARTED'
      });

      const response = await getCustodians(pageNumber, pageSize, searchString);
      dispatch({
        type: 'CUSTODIANS_LIST_FETCH_SUCCESS',
        payload: {
          custodians: response.result,
          custodiansCount: response.totalCount
        }
      });
    } catch (error) {
      Errors.handle(error);
      dispatch({
        type: 'CUSTODIANS_LIST_FETCH_ERROR'
      });
    }
  },
  doGetCustodianIdentities: (custodianId) => async (dispatch) => {
    try {
      dispatch({
        type: 'CUSTODIAN_IDENTITIES_LIST_FETCH_STARTED'
      });

      const response = await getCustodianIdentities(custodianId);
      dispatch({
        type: 'CUSTODIAN_IDENTITIES_LIST_FETCH_SUCCESS',
        payload: {
          userIdentities: response
        }
      });
    } catch (error) {
      Errors.handle(error);
      dispatch({
        type: 'CUSTODIAN_IDENTITIES_LIST_FETCH_ERROR'
      });
    }
  },
  doSetSelectedCustodians: (selectedCustodians) => async (dispatch) => {
    try {
      dispatch({
        type: 'SELECTED_CUSTODIANS_LIST_FETCH_STARTED'
      });
      dispatch({
        type: 'SELECTED_CUSTODIANS_LIST_FETCH_SUCCESS',
        payload: {
          selectedCustodians: selectedCustodians
        }
      });
    } catch (error) {
      Errors.handle(error);
      dispatch({
        type: 'SELECTED_CUSTODIANS_LIST_FETCH_ERROR'
      });
    }
  },
  doSetSelectedExternalCustodians: (selectedExternalCustodians) => async (dispatch) => {
    try {
      dispatch({
        type: 'SELECTED__EXTERNAL_CUSTODIANS_LIST_FETCH_STARTED'
      });
      dispatch({
        type: 'SELECTED_EXTERNAL_CUSTODIANS_LIST_FETCH_SUCCESS',
        payload: {
          selectedExternalCustodians: selectedExternalCustodians
        }
      });
    } catch (error) {
      Errors.handle(error);
      dispatch({
        type: 'SELECTED_EXTERNAL_CUSTODIANS_LIST_FETCH_ERROR'
      });
    }
  },
  doGetAllCustodiansData: (custodiansList) => async (dispatch) => {
    try {
      dispatch({
        type: 'ALL_CUSTODIANS_LIST_FETCH_STARTED'
      });

      const response = await getAllCustodiansData(custodiansList);
      dispatch({
        type: 'ALL_CUSTODIANS_LIST_FETCH_SUCCESS',
        payload: {
          allCustodiansList: response?.result,
          allCustodiansCount: response?.totalCount
        }
      });
    } catch (error) {
      Errors.handle(error);
      dispatch({
        type: 'ALL_CUSTODIANS_LIST_FETCH_ERROR'
      });
    }
  },
  doOpenCustodianConfirmation: () => async (dispatch) => {
    dispatch({
      type: 'LEGALHOLD_FORM_CUSTODIAN_SELECTION_OPEN'
    });
  },
  doCloseCustodianConfirmation: () => async (dispatch) => {
    dispatch({
      type: 'LEGALHOLD_FORM_CUSTODIAN_SELECTION_CLOSE'
    });
  },
  doOpenInternalSelection: () => async (dispatch) => {
    dispatch({
      type: 'LEGALHOLD_FORM_INTERNAL_CUSTODIAN_SELECTION_OPEN'
    });
  },
  doCloseInternalSelection: () => async (dispatch) => {
    dispatch({
      type: 'LEGALHOLD_FORM_INTERNAL_CUSTODIAN_SELECTION_CLOSE'
    });
  },
  doSetSelectedCustodiansCount: (count) => async (dispatch) => {
    dispatch({
      type: 'LEGALHOLD_FORM_SET_SELECTED_CUSTODIANS_COUNT',
      payload: {
        count
      }
    });
  },
  doGetChannels: (containersList) => async (dispatch) => {
    try {
      dispatch({
        type: 'ALL_CHANNELS_LIST_FETCH_STARTED'
      });
      const response = await getChannels(containersList);
      dispatch({
        type: 'ALL_CHANNELS_LIST_FETCH_SUCCESS',
        payload: {
          channelsList: response
        }
      });
    } catch (error) {
      Errors.handle(error);
      dispatch({
        type: 'ALL_CHANNELS_LIST_FETCH_ERROR'
      });
    }
  },
  doCreateChatLH: (values) => async (dispatch) => {
    try {
      dispatch({
        type: 'LEGALHOLD_CHAT_FORM_CREATE_STARTED'
      });
      await createChatLH(values).then((res) => {
        dispatch({
          type: 'LEGALHOLD_CHAT_FORM_CREATE_SUCCESS',
          payload: {
            chatLegalHoldId: res
          }
        });
        toast.success('Legal Hold saved!', { icon: false });
      });
    } catch (error) {
      Errors.handle(error);
      dispatch({
        type: 'LEGALHOLD_CHAT_FORM_CREATE_ERROR'
      });
    }
  },
  doConfirmDiscardChatLH: (legalHoldId, isConfirmed) => async (dispatch) => {
    try {
      dispatch({
        type: 'LEGALHOLD_CHAT_CONFIRM_OR_DISCARD_STARTED'
      });
      await confirmDiscardChatLH(legalHoldId, isConfirmed).then((res) => {
        dispatch({
          type: 'LEGALHOLD_CHAT_CONFIRM_OR_DISCARD_SUCCESS',
          payload: {
            chatLegalHold: res
          }
        });
        toast.success(`${isConfirmed ? 'Legal Hold confirmed!' : 'Legal Hold discarded!'}`, {
          icon: false
        });
      });
    } catch (error) {
      Errors.handle(error);
      dispatch({
        type: 'LEGALHOLD_CHAT_CONFIRM_OR_DISCARD_ERROR'
      });
    }
  },
  doOpenChatLH: () => async (dispatch) => {
    dispatch({
      type: 'LEGALHOLD_CHAT_FORM_OPEN_CONFIRM'
    });
  },
  doCloseChatLH: () => async (dispatch) => {
    dispatch({
      type: 'LEGALHOLD_CHAT_FORM_CLOSE_CONFIRM'
    });
  },
  doDiscardChatLH: () => async (dispatch) => {
    dispatch({
      type: 'LEGALHOLD_CHAT_DISCARD_CONFIRM'
    });
  }
};

export default actions;
