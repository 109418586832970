import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'utils/withRouter';
import OneDriveForm from './OneDriveForm';
import actions from 'actions/OneDrive/OneDriveFormActions';
import config from 'config';

class OneDriveFormPage extends Component {
  componentDidMount() {
    const { dispatch, navigate } = this.props;
    const legalHoldType = config.legalHoldEnum.File;
    dispatch(actions.doFindContainers(legalHoldType));
    dispatch(actions.doFindLegalHoldNames(this.props?.legalCaseId));
  }

  render() {
    return (
      <>
        <OneDriveForm
          currentUser={this.props.currentUser}
          containers={this.props.containers}
          history={this.props.history}
          legalHoldNames={this.props.legalHoldNames}
          dispatch={this.props.dispatch}
          currentTheme={this.props.currentTheme}
          legalCaseId={this.props.legalCaseId}
          currentStep={this.props.currentStep}
          pageNumber={this.props.pageNumber}
          pageSize={this.props.pageSize}
          selectedCustodiansList={this.props.selectedCustodiansList}
          selectedExternalCustodians={this.props.selectedExternalCustodians}
          allCustodiansList={this.props.allCustodiansList}
          userIdentities={this.props.userIdentities}
          openInternalSelectionModal={this.props.openInternalSelectionModal}
          temporaryCustodiansCount={this.props.temporaryCustodiansCount}
          createdLegalHoldId={this.props.createdLegalHoldId}
          channelsList={this.props.channelsList}
          navigate={this.props.navigate}
          custodians={this.props.custodians}
          custodiansCount={this.props.custodiansCount}
        />
      </>
    );
  }
}

function mapStateToProps(store) {
  return {
    containers: store.OneDrive.form.containers,
    currentUser: store.auth.currentUser,
    rows: store.OneDrive.list.rows,
    legalHoldNames: store.OneDrive.form.legalHoldNames,
    currentTheme: store.layout.currentTheme,
    currentStep: store.OneDrive.form.currentStep,
    selectedCustodiansList: store.OneDrive.form.selectedCustodiansList,
    selectedExternalCustodians: store.OneDrive.form.selectedExternalCustodians,
    allCustodiansList: store.OneDrive.form.allCustodiansList,
    userIdentities: store.OneDrive.form.userIdentities,
    openInternalSelectionModal: store.OneDrive.form.openInternalSelectionModal,
    temporaryCustodiansCount: store.OneDrive.form.temporaryCustodiansCount,
    createdLegalHoldId: store.OneDrive.form.createdLegalHoldId,
    openFileModal: store.OneDrive.form.openFileModal,
    custodians: store.OneDrive.form.custodians,
    custodiansCount: store.OneDrive.form.custodiansCount
  };
}

export default withRouter(connect(mapStateToProps)(OneDriveFormPage));
