import { defaultOptions } from 'services/userService';
import Errors from 'components/FormItems/error/errors';
import config from 'config';
import { isLegalAdmin } from 'utils/isLegalAdmin';
import { store } from 'index';
import { downloadService } from 'services/downloadService';

async function getDirectoriesHierarchy(id) {
  const response = await fetch(
    `${config.externalApi}/legal/api/OneDriveLegalHold/Hierarchy?legalHoldId=${id}`,
    {
      ...defaultOptions,
      method: 'GET'
    }
  );

  const data = await response.json();
  return data;
}

async function getFilesList(path) {
  const response = await fetch(
    `${config.externalApi}/legal/api/OneDriveLegalHold/Files?folderPath=${path}`,
    {
      ...defaultOptions,
      method: 'GET'
    }
  );

  const data = await response.json();
  return data;
}

async function list(searchString, pageNumber, pageSize, legalCaseId, sortOrder) {
  const currentUser = store.getState().auth.currentUser?.userName;
  const legalAdmin = isLegalAdmin(currentUser);
  let baseUrl = `${config.externalApi}/legal/api/OneDriveLegalHold?`;
  if (pageNumber !== undefined) {
    baseUrl += 'pageNumber=' + encodeURIComponent('' + pageNumber) + '&';
  }
  if (pageSize !== undefined) {
    baseUrl += 'pageSize=' + encodeURIComponent('' + pageSize) + '&';
  }
  if (searchString !== undefined && searchString !== '') {
    baseUrl += 'searchString=' + encodeURIComponent('' + searchString) + '&';
  }
  if (sortOrder !== undefined && sortOrder !== '') {
    baseUrl += 'sortOrder=' + encodeURIComponent('' + sortOrder) + '&';
  }
  if (legalCaseId !== undefined && legalCaseId !== '') {
    baseUrl += 'legalCaseId=' + encodeURIComponent('' + legalCaseId) + '&';
  }
  if (legalAdmin !== undefined && legalAdmin !== null) {
    baseUrl += `isLegalAdmin=` + encodeURIComponent('' + legalAdmin) + '&';
  }
  if (currentUser !== undefined && currentUser !== null) {
    baseUrl += `loggedUser=` + encodeURIComponent('' + currentUser) + '&';
  }
  baseUrl = baseUrl.replace(/[?&]$/, '');
  const response = await fetch(baseUrl, { ...defaultOptions, method: 'GET' });

  const data = await response.json();
  return data;
}

async function exportToCSV(downloadUrl, currentUser, legalCaseId, searchString, columnHeaders) {
  const { name: firstName, surName: lastName, email, userName } = currentUser || {};
  const data = { firstName, lastName, email, userName };
  const legalAdmin = isLegalAdmin(currentUser);
  const loggedUser = currentUser?.userName;

  let baseUrl = downloadUrl;
  if (legalCaseId !== undefined && legalCaseId !== null) {
    baseUrl += 'legalCaseId=' + encodeURIComponent('' + legalCaseId) + '&';
  }
  if (searchString !== undefined && searchString !== null && searchString !== '') {
    baseUrl += 'searchString=' + encodeURIComponent('' + searchString) + '&';
  }
  if (columnHeaders !== undefined && columnHeaders !== null) {
    baseUrl += 'columnHeaders=' + encodeURIComponent('' + columnHeaders) + '&';
  }
  if (legalAdmin !== undefined && legalAdmin !== null) {
    baseUrl += 'isLegalAdmin=' + encodeURIComponent('' + legalAdmin) + '&';
  }
  if (loggedUser !== undefined && loggedUser !== null) {
    baseUrl += 'loggedUser=' + encodeURIComponent('' + loggedUser) + '&';
  }
  baseUrl = baseUrl.replace(/[?&]$/, '');
  await downloadService(baseUrl, 'POST', data);
}

async function exportToPDF(downloadUrl, currentUser, legalCaseId, searchString, columnHeaders) {
  const { name: firstName, surName: lastName, email, userName } = currentUser || {};
  const data = { firstName, lastName, email, userName };
  const legalAdmin = isLegalAdmin(currentUser);
  const loggedUser = currentUser?.userName;

  let baseUrl = downloadUrl;
  if (legalCaseId !== undefined && legalCaseId !== null) {
    baseUrl += 'legalCaseId=' + encodeURIComponent('' + legalCaseId) + '&';
  }
  if (searchString !== undefined && searchString !== null && searchString !== '') {
    baseUrl += 'searchString=' + encodeURIComponent('' + searchString) + '&';
  }
  if (columnHeaders !== undefined && columnHeaders !== null) {
    baseUrl += 'columnHeaders=' + encodeURIComponent('' + columnHeaders) + '&';
  }
  if (legalAdmin !== undefined && legalAdmin !== null) {
    baseUrl += 'isLegalAdmin=' + encodeURIComponent('' + legalAdmin) + '&';
  }
  if (loggedUser !== undefined && loggedUser !== null) {
    baseUrl += 'loggedUser=' + encodeURIComponent('' + loggedUser) + '&';
  }
  baseUrl = baseUrl.replace(/[?&]$/, '');
  await downloadService(baseUrl, 'POST', data);
}
const actions = {
  doGetDirectoriesHierarchy: (id) => async (dispatch) => {
    try {
      dispatch({
        type: 'LEGALHOLD_DIRECTORIES_LIST_FETCH_STARTED'
      });

      const response = await getDirectoriesHierarchy(id);
      dispatch({
        type: 'LEGALHOLD_DIRECTORIES_LIST_FETCH_SUCCESS',
        payload: {
          directoriesList: response
        }
      });
    } catch (error) {
      Errors.handle(error);

      dispatch({
        type: 'LEGALHOLD_DIRECTORIES_LIST_FETCH_ERROR'
      });
    }
  },
  doGetFilesList: (path) => async (dispatch) => {
    try {
      dispatch({
        type: 'LEGALHOLD_FILES_LIST_FETCH_STARTED'
      });

      const response = await getFilesList(path);
      dispatch({
        type: 'LEGALHOLD_FILES_LIST_FETCH_SUCCESS',
        payload: {
          filesList: response
        }
      });
    } catch (error) {
      Errors.handle(error);

      dispatch({
        type: 'LEGALHOLD_FILES_LIST_FETCH_ERROR'
      });
    }
  },
  doFetchFilesLH:
    (filter, keepPagination = false) =>
    async (dispatch) => {
      try {
        dispatch({
          type: 'FILE_LEGALHOLD_LIST_FETCH_STARTED',
          payload: { filter, keepPagination }
        });

        const response = await list(
          filter.searchString || '',
          filter.pageNumber || 1,
          filter.pageSize || 5,
          filter.legalCaseId,
          filter.sortOrder
        );
        dispatch({
          type: 'FILE_LEGALHOLD_LIST_FETCH_SUCCESS',
          payload: {
            rows: response.legalHolds,
            count: response.totalCount
          }
        });
      } catch (error) {
        Errors.handle(error);
        dispatch({
          type: 'FILE_LEGALHOLD_LIST_FETCH_ERROR'
        });
      }
    },
  handleExportToCSV:
    (downloadUrl, currentUser, legalCaseId, searchString, columnHeaders) => async (dispatch) => {
      try {
        dispatch({
          type: 'FILE_LEGALHOLD_EXPORT_TO_CSV_STARTED'
        });

        exportToCSV(downloadUrl, currentUser, legalCaseId, searchString, columnHeaders).then(() => {
          dispatch({
            type: 'FILE_LEGALHOLD_EXPORT_TO_CSV_SUCCESS'
          });
        });
      } catch (error) {
        Errors.handle(error);
        dispatch({
          type: 'FILE_LEGALHOLD_EXPORT_TO_CSV_ERROR'
        });
      }
    },
  handleExportToPDF:
    (downloadUrl, currentUser, legalCaseId, searchString, columnHeaders) => async (dispatch) => {
      try {
        dispatch({
          type: 'FILE_LEGALHOLD_EXPORT_TO_PDF_STARTED'
        });

        exportToPDF(downloadUrl, currentUser, legalCaseId, searchString, columnHeaders).then(() => {
          dispatch({
            type: 'FILE_LEGALHOLD_EXPORT_TO_PDF_SUCCESS'
          });
        });
      } catch (error) {
        Errors.handle(error);
        dispatch({
          type: 'FILE_LEGALHOLD_EXPORT_TO_PDF_ERROR'
        });
      }
    }
};

export default actions;
