import React, { useState } from 'react';
import { Tree } from 'primereact/tree';
import 'components/CRUD/Search/view/TreeView.css';
import actions from 'actions/OneDrive/OneDrivePreviewActions';
import { LuFolder } from 'react-icons/lu';
import { LuFolderOpen } from 'react-icons/lu';
import { RxCaretRight } from 'react-icons/rx';
import { RxCaretDown } from 'react-icons/rx';

export default function DirectoriesView({ nodes, dispatch }) {
  const [selectedNodeKey, setSelectedNodeKey] = useState('');
  const [expandedKey, setExpandedKey] = useState('');

  const onExpand = (event) => {
    console.log('Node Expanded');
  };

  const onCollapse = (event) => {
    console.log('Node Collapsed');
  };

  const onSelect = (event) => {
    console.log('Node Selected');
  };

  const onUnselect = (event) => {
    console.log('Node Unselected');
  };
  const nodeTemplate = (node, options) => {
    return <span style={{ cursor: 'pointer' }}>{node.name}</span>;
  };

  const togglerTemplate = (node, options) => {
    if (!node) {
      return;
    }
    const expanded = options.expanded;
    const hasChildren = node.children?.length !== 0;
    const level = node?.key?.split('-')?.length;
    const marginLeft = `${level * 15}px`;
    return (
      <span onClick={options.onClick} style={{ cursor: 'pointer' }}>
        {!expanded && hasChildren ? (
          <p style={{ marginLeft, marginRight: '5px', display: 'flex', marginBottom: '0px' }}>
            <span>
              <RxCaretRight />
            </span>
            <span className='ms-2'>
              <LuFolder />
            </span>
          </p>
        ) : expanded && hasChildren ? (
          <p style={{ marginLeft, marginRight: '5px', display: 'flex', marginBottom: '0px' }}>
            <span>
              <RxCaretDown />
            </span>
            <span className='ms-2'>
              <LuFolderOpen />
            </span>
          </p>
        ) : (
          <p style={{ marginLeft, marginRight: '5px', display: 'flex', marginBottom: '0px' }}>
            <span className='ms-3'></span>
            <span className='ms-2'>
              <LuFolder />
            </span>
          </p>
        )}
      </span>
    );
  };

  const findNodePath = (nodeKey, nodes, path = '') => {
    for (let node of nodes) {
      const currentPath = path ? `${path}/${node.name}` : node.name;

      if (node.key === nodeKey) {
        return currentPath;
      }

      if (node.children && node.children.length > 0) {
        const childPath = findNodePath(nodeKey, node.children, currentPath);
        if (childPath) {
          return childPath;
        }
      }
    }

    return null; // Return null if node is not found
  };

  const handleNodeSelect = (e) => {
    const nodeKey = e.node.key;
    const path = findNodePath(nodeKey, nodes);
    dispatch(actions.doGetFilesList(path));
  };

  return (
    <div className='tree-container'>
      <Tree
        value={nodes}
        nodeTemplate={nodeTemplate}
        togglerTemplate={togglerTemplate}
        className='p-tree-container'
        selectionMode='single'
        selectionKeys={selectedNodeKey}
        onSelectionChange={(e) => setSelectedNodeKey(e.value)}
        onSelect={handleNodeSelect}
        expandedKeys={expandedKey}
        // onExpand={onExpand}
        // onCollapse={onCollapse}
        // onUnselect={onUnselect}
      />
    </div>
  );
}
